import React from "react";

import "./index.scss";

import StdPage from "../../comps/stdpage";
import SubPage from "../../comps/subpage";
import Content from "../../comps/content";

//Fotos
import header from "./media/header.jpg";

class Bewerbung extends React.Component {
  render() {
    return (
      <StdPage title="Bewerbung" header={header} focusPoint="84% 50%">
        <SubPage naviTitle="Talent" className="bottomline">
          <Content>
            <h3>Berufswahl Journalismus</h3>
            <h2>#Talent #Herz #Hirn</h2>
            <p>
              Die Ringier Journalistenschule bietet dir eine erstklassige Ausbildung. Die Volontärinnen und Volontäre erhalten ihre praktische Ausbildung im redaktionellen Alltag, während die theoretischen Grundlagen im Unterricht vermittelt werden. Dieses duale Konzept orientiert sich am Erfolgsmodell der Schweizer Berufslehre.
            </p>
            <p>
              Der neue Lehrgang 2025-2027 beginnt am 18. August 2025. Einsendeschluss für Bewerbungen ist der <strong>31. Januar 2025.</strong>
            </p>
          </Content>
        </SubPage>

        <SubPage naviTitle="Du bringst" className="bottomline">
          <Content>
            <h2>Das erwarten wir</h2>
            <ul>
              <li>Du bist neugierig, intelligent, digital versiert und arbeitest präzise</li>
              <li>Du hast viele Ideen und Lust, sie umzusetzen</li>
              <li>Du erzählst gerne Geschichten und versuchst, der Wahrheit so nah wie möglich zu kommen</li>
              <li>Du hast einen Beruf gelernt, eine Matura oder einen Studienabschluss im Sack</li>
              <li>Du bringst erste journalistische Erfahrungen mit</li>
            </ul>
          </Content>
        </SubPage>

        <SubPage naviTitle="Wir bringen" className="bottomline">
          <Content>
            <h2>Das bieten wir</h2>
            <ul>
              <li>Du lernst alles, was den Journalismus ausmacht</li>
              <li>Deine praktische Ausbildung absolvierst du direkt in der Redaktion</li>
              <li>Du lernst und arbeitest mit den Besten der Branche</li>
              <li>Du bringst Ideen ein, recherchierst, schreibst Artikel für Print und Online und erstellst eigene Video- oder Audio-Beiträge</li>
            </ul>
          </Content>
        </SubPage>

        <SubPage naviTitle="Das erwartet dich" className="bottomline">
          <Content>
            <h2>Was du erwarten kannst</h2>
            <ul>
              <li>Die Redaktionen befinden sich an den Standorten Pressehaus (Zürich Seefeld) oder Medienpark (Zürich Altstetten)</li>
              <li>Im ersten Jahr verdienst du brutto CHF 2'000.00 und im zweiten Jahr brutto 4'000.00 im Monat</li>
              <li>Während den Schulwochen isst du jeweils gratis</li>
              <li>Vergünstigungen beim öffentlichen Verkehr</li>
            </ul>
          </Content>
        </SubPage>

        <SubPage naviTitle='Bewerbung' className='bottomline'>
              <Content>

                <h2>Bewirb dich</h2>
                <p>Interessiert? Dann freuen wir uns auf deine Bewerbung.</p>
                <ul>
                  <li>Schreibe eine Reportage zum Thema «Dichtestress» (4000 Zeichen) und drehe dazu ein Smartphone-Video (1–3 Minuten)</li>
                  <li>Schicke uns ausserdem dein Motivationsschreiben, deinen Lebenslauf, Zeugnisse, Diplome und Arbeitsproben</li>
                </ul>
                <p>Wir bekennen Farbe und sind überzeugt, dass Vielfalt eine Chance ist. Alle sollen genau so sein, wie sie sind. Wir kreieren ein Arbeitsumfeld, in dem das möglich ist.</p>
                <p>
                  Einsendeschluss: 31. Januar 2025
                </p>
                <p><a href="https://jobs.ringier.ch/offene-stellen/volontaer-in-ringier-journalistenschule/25c8893d-6378-455c-90a3-df0a538b49ac" target="_blank" rel="noopener noreferrer">Bewirb dich jetzt</a></p>

              </Content>
          </SubPage>
      </StdPage>
    );
  }
}

export default Bewerbung;
