import React from 'react';

import './index.scss';

import StdPage from '../../comps/stdpage';
import SubPage from '../../comps/subpage'
import Content from '../../comps/content';
import CollapseText from '../../comps/collapsetext';

//Fotos
import header from './media/header.jpg';

class Alumni extends React.Component {
  render()
  {
    return (
        <StdPage title='Alumni' header={header}>

          <SubPage naviTitle='Rede'>
            <Content>
              <h3>Diplomierte der Ringier Journalistenschule</h3>
              <h2>Das «Who is Who» der Deutschschweizer Medienbranche</h2>

              <CollapseText text='Jahrgang 1974–1976'>
                <ul>
                  <li>Aeberli Oskar</li>
                  <li>Biffaud-Schmid Regula</li>
                  <li>Billerbeck Ewald</li>
                  <li>Brassel Margrit</li>
                  <li>Brotschi Urs</li>
                  <li>Cantieni Benita</li>
                  <li>Conne Jean-Louis</li>
                  <li>Eckert Heinz</li>
                  <li>Etter-Thomann Liselotte</li>
                  <li>Gebele Rolf</li>
                  <li>Gygax René E.</li>
                  <li>Hunn Elisabeth</li>
                  <li>Klein Christoph</li>
                  <li>Müller Rolf C.</li>
                  <li>Ringier Michael</li>
                  <li>Ritter Hans</li>
                  <li>Trüb Thomas</li>
                  <li>Weber Heinz</li>
                  <li>Widmer-Egger Monica</li>
                  <li>Yoder-Brunnschweiler Christine</li>
                  <li>Zücker Irène</li>
                </ul>
              </CollapseText>
              
              <CollapseText text='Jahrgang 1977–1978'>
                <ul>
                  <li>Auf der Maur Jost</li>
                  <li>Bänziger Kathrin</li>
                  <li>Bircher-Streit Barbara</li>
                  <li>Brugger Hanspeter</li>
                  <li>Diethelm Richard</li>
                  <li>Flubacher Rita</li>
                  <li>Frefel Astrid</li>
                  <li>Haller Markus</li>
                  <li>Hämmerli Fredy</li>
                  <li>Hoffmann Maja</li>
                  <li>Imhof Paul</li>
                  <li>Langer-Ingold Verena</li>
                  <li>Lehmann Jürg</li>
                  <li>Marthaler Peter</li>
                  <li>Meyer Charles</li>
                  <li>Neininger Norbert</li>
                  <li>Neininger Marie-Christine</li>
                  <li>Schüz Daniel</li>
                  <li>Strübin Christian</li>
                  <li>Zoller Raymond</li>
                  <li>Zurlinden Urs</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1979–1980'>
                <ul>
                  <li>Bächtold Daniel</li>
                  <li>Bernasconi Carlo</li>
                  <li>Christen Anton</li>
                  <li>Eigner Hans-Christian</li>
                  <li>Fehlmann Rolf</li>
                  <li>Früh Peter</li>
                  <li>Furrer Michael</li>
                  <li>Gartenmann Cil</li>
                  <li>Gmurczyk Edward</li>
                  <li>Hasler Barbara</li>
                  <li>Hollenstein Beat</li>
                  <li>Kienle Paul</li>
                  <li>Kunz Maria Cyrilla</li>
                  <li>Laszlo Béla</li>
                  <li>Lübke Frank</li>
                  <li>Ott Hans</li>
                  <li>Schläpfer Martin</li>
                  <li>Specogna-Hoffmann Heidi</li>
                  <li>Zweifel Edith</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1981–1982'>
                <ul>
                  <li>Accola Thomas</li>
                  <li>Arn-Benaoun Danielle</li>
                  <li>Bachmann Ueli</li>
                  <li>Berke Claudia</li>
                  <li>Bühler-Michel Peter</li>
                  <li>Emch Markus</li>
                  <li>Gasser Heini</li>
                  <li>Geel Eva</li>
                  <li>Heer Gret</li>
                  <li>Holz Eva</li>
                  <li>Jeanneret-Grosjean James</li>
                  <li>Kobelt Evelyn</li>
                  <li>Kübler Markus</li>
                  <li>Loeb Christoph</li>
                  <li>Maeder Ruedi</li>
                  <li>Mahnig Emil</li>
                  <li>Meierhofer Ernst</li>
                  <li>Mettler Urs</li>
                  <li>Perrig Caesar</li>
                  <li>Schneider Ruth</li>
                  <li>Wartmann-Scherrer Claudia</li>
                  <li>Wyss Eva</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1983–1984'>
                <ul>
                  <li>Ammann Christoph</li>
                  <li>Buess-Fascati Urs </li>
                  <li>Gartmann-Maurer Ruth</li>
                  <li>Harisberger Roland</li>
                  <li>Holzgang Reto</li>
                  <li>Howald Stefan</li>
                  <li>Kammann George</li>
                  <li>Kistler Valéry</li>
                  <li>Krummenacher Zurgilgen Elisabeth</li>
                  <li>Löpfe Philipp</li>
                  <li>Lüthy Leo</li>
                  <li>Lukesch Barbara</li>
                  <li>Müller Rudolf </li>
                  <li>Naef Urs Peter </li>
                  <li>Nussbaum Jürg</li>
                  <li>Reynolds-Auchli Eva</li>
                  <li>Sieber-Trüb Roland</li>
                  <li>Staubli René</li>
                  <li>Stutz Elisabeth</li>
                  <li>Weder-Arlitt Sabine</li>
                  <li>Zaugg Brigitte</li>
                  <li>Zumbühl-Bühler Mark</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1985–1986'>
                <ul>
                  <li>Blickenstorfer Daniel</li>
                  <li>Duttweiler Catherine</li>
                  <li>Etique Claude</li>
                  <li>Flüeler Thomas</li>
                  <li>Glauser Julia</li>
                  <li>Hagger Marius</li>
                  <li>Iten Marianne</li>
                  <li>Kauz Magdalena</li>
                  <li>Kenner Thomas</li>
                  <li>Loeb Kaspar</li>
                  <li>Leuthold Hansheinrich</li>
                  <li>Perrig Severin</li>
                  <li>Schär Markus</li>
                  <li>Schneeberger Hans</li>
                  <li>Schmezer Ueli</li>
                  <li>Settele Claude</li>
                  <li>Spring Roy P.</li>
                  <li>Suter Martin</li>
                  <li>Tavoli-Egger Jolanda</li>
                  <li>Ungerer Heidi</li>
                  <li>Wartmann Margrit</li>
                  <li>Woerdehoff Esther-A.</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1987–1988'>
                <ul>
                  <li>Augstburger Urs</li>
                  <li>Barmettler Stefan</li>
                  <li>Beglinger Martin</li>
                  <li>Bucher Simone</li>
                  <li>Eisner Miriam</li>
                  <li>Fingerhuth Bichsel Linka </li>
                  <li>Gerny Daniel</li>
                  <li>Hochuli Susanne</li>
                  <li>Hubacher Simon</li>
                  <li>Lehmeier Anita</li>
                  <li>Meister Marianne</li>
                  <li>Perrig-Heeb Andrea</li>
                  <li>Probst Philipp</li>
                  <li>Ruoss-Gees Eva-Maria</li>
                  <li>Sohns Harald</li>
                  <li>Spörri Balz</li>
                  <li>Urech Janine</li>
                  <li>Van Orsouw Michael</li>
                  <li>Von Arx Mirjam</li>
                  <li>Wanner-Müller Franziska</li>
                  <li>Winkler Michael</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1989–1990'>
                <ul>
                  <li>Amacker Barbara</li>
                  <li>Baumgartner Christoph</li>
                  <li>Deicher Daniel</li>
                  <li>Encrantz Johann</li>
                  <li>Furrer Martin </li>
                  <li>Gubler Christina</li>
                  <li>Janett Jachen</li>
                  <li>Kind Yvonne</li>
                  <li>Kopp Barbara</li>
                  <li>Langenegger Denise</li>
                  <li>Leuppi John</li>
                  <li>Marty Kurt</li>
                  <li>Platz Christian</li>
                  <li>Rüfli Karin</li>
                  <li>Schmid Erica</li>
                  <li>Schneider Reto U.</li>
                  <li>Sieber Andrea</li>
                  <li>Signer Claudine</li>
                  <li>Sonder Dario</li>
                  <li>Teuwsen Peer</li>
                  <li>Willmann Urs </li>
                  <li>Wysshaar Arndt Barbara</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1991–1992'>
                <ul>
                  <li>Berchtold Andrea</li>
                  <li>Bonin Gaby</li>
                  <li>Bühler Lucas</li>
                  <li>Dahinden-Stadler Maja</li>
                  <li>Doka Caroline</li>
                  <li>Gurzeler Veronica</li>
                  <li>Hofer Martin</li>
                  <li>Kern Esther</li>
                  <li>Kohl Pascale</li>
                  <li>Küng Max</li>
                  <li>Müller Andreas</li>
                  <li>Müller Niels</li>
                  <li>Noser Marianne</li>
                  <li>Seiler Barbara</li>
                  <li>Seiler Benjamin</li>
                  <li>Steffan Sandra</li>
                  <li>Stettler Susanne</li>
                  <li>Tobler-Studer Regula</li>
                  <li>Tschudin André</li>
                  <li>Ziegler Sandra</li>
                  <li>Zünd Jacqueline</li>
                  <li>Zürcher Christoph</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1993–1994'>
                <ul>
                  <li>Arnet Daniel</li>
                  <li>Balzli Peter</li>
                  <li>Bauer Karin</li>
                  <li>Huber Regula</li>
                  <li>Böhni Matthias</li>
                  <li>Diòda Karin</li>
                  <li>Dütschler Christian</li>
                  <li>Fabian Daniela</li>
                  <li>Güntert Andreas</li>
                  <li>Hertach Dominik</li>
                  <li>Hirt Marco</li>
                  <li>Huser Karin</li>
                  <li>Kotas Andrea</li>
                  <li>Reinhart Stefan</li>
                  <li>Ruf-Tufan Cigdem</li>
                  <li>Schmuki Philippe</li>
                  <li>Schnetzler Nadja</li>
                  <li>Sonderegger Gregor</li>
                  <li>Teuscher Patrick</li>
                  <li>von Wartburg Claudia</li>
                  <li>Walder Marc</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1997–1998'>
                <ul>
                  <li>Allemann Sarah</li>
                  <li>Binggeli Silvia Debora</li>
                  <li>Bleicher Andrea</li>
                  <li>Borri Mario</li>
                  <li>Büchi Corinne</li>
                  <li>Gassiot Lorena</li>
                  <li>Knobel Reto</li>
                  <li>Krenger Beat</li>
                  <li>Maschek André</li>
                  <li>Mauron Patrick</li>
                  <li>Michel Beat</li>
                  <li>Müller Irène</li>
                  <li>Munz Detlev</li>
                  <li>Sames Manuela</li>
                  <li>Schäfer Daniel</li>
                  <li>Stark Alexandra</li>
                  <li>Stillhart Sibylle</li>
                  <li>Wyss Michael</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 1999–2000'>
                <ul>
                  <li>Becker David</li>
                  <li>Braun Gabriela</li>
                  <li>Bühler Simon</li>
                  <li>Dorer Christian</li>
                  <li>Fischer-Schulthess Andrea</li>
                  <li>Kägi Marianne</li>
                  <li>Lendenmann Ariane</li>
                  <li>Merli Nina</li>
                  <li>Moll Thomas</li>
                  <li>Oertli Mirjam</li>
                  <li>Plüss Matthias</li>
                  <li>Sahli Michael</li>
                  <li>Senn Thomas</li>
                  <li>Stalder Tuuli</li>
                  <li>Vincenz Gion-Duri</li>
                  <li>Wittwer Sabine</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2001-2002'>
                <ul>
                  <li>Frei Martina</li>
                  <li>Frischknecht Stéphanie</li>
                  <li>Gatschet Rolf</li>
                  <li>Hehli Simon</li>
                  <li>Honegger Arthur</li>
                  <li>Klotzbach Cécile</li>
                  <li>Koechlin Simon</li>
                  <li>Kummer Yvonne</li>
                  <li>Kuster Roger</li>
                  <li>Lüssi Marco</li>
                  <li>Moser Manuela</li>
                  <li>Rindlisbacher Sabine</li>
                  <li>Rutschmann Martina</li>
                  <li>Schmidlin Thomas</li>
                  <li>Seemann Isabella</li>
                  <li>Wüthrich Christa</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2003-2004'>
                <ul>
                  <li>Aschwanden Christine</li>
                  <li>Blankenhorn Anja</li>
                  <li>Böni Andreas</li>
                  <li>Ehinger Markus</li>
                  <li>Ehrensperger Sulamith</li>
                  <li>Freund Monika</li>
                  <li>Gehri Philip</li>
                  <li>Gustin Reto</li>
                  <li>Heuss Adrian</li>
                  <li>Kappeler Rémy</li>
                  <li>Leuthold Juliette</li>
                  <li>Lindner Christian</li>
                  <li>Roth Christian</li>
                  <li>Schärer Caspar</li>
                </ul>
              </CollapseText>

              <CollapseText text='Förderlehrgang 2006'>
                <ul>
                  <li>Brandle Rico</li>
                  <li>Baur Roger</li>
                  <li>Dorer Christian</li>
                  <li>Estermann Edi</li>
                  <li>Fichter Manuel</li>
                  <li>Hadorn Lukas</li>
                  <li>Hug Dominik</li>
                  <li>Odermatt Marcel</li>
                  <li>Reich Markus</li>
                  <li>Ryter Barbara</li>
                  <li>Schmid François</li>
                  <li>Vogel Benita</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2007-2008'>
                <ul>
                  <li>Anthon Erika</li>
                  <li>Büschlen Tino</li>
                  <li>Füglister Lukas</li>
                  <li>Halter Barbara</li>
                  <li>Hänni Astrid</li>
                  <li>Hässig Peter</li>
                  <li>Hof Leonie</li>
                  <li>Kohli Andreas</li>
                  <li>Neumann Roman</li>
                  <li>Riederer Isabelle</li>
                  <li>Roschi Stefan</li>
                  <li>Rotzinger Ulrich</li>
                  <li>Tibolla Rinaldo</li>
                  <li>Wasik Roman</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2009'>
                <ul>
                  <li>Bart Gabriela</li>
                  <li>Blaser Franziska</li>
                  <li>Catrina Laura</li>
                  <li>Freudiger Nicole</li>
                  <li>Gertschen Mario</li>
                  <li>Honegger Lorenz</li>
                  <li>Kempa Silvie</li>
                  <li>König Tanya</li>
                  <li>Kramer Pirmin</li>
                  <li>Lavoyer Sébastian</li>
                  <li>Merz Lisa</li>
                  <li>Ruderer Malena</li>
                  <li>Schaffner Florian</li>
                  <li>Vonhoff Anna</li>
                  <li>Wyss Christian</li>
                  <li>Zivadinovic Marijana</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2011-2012'>
                <ul>
                  <li>Bünter Evi</li>
                  <li>Dutler Alex</li>
                  <li>Estermann Frank</li>
                  <li>Fink Vanessa</li>
                  <li>Fitz Alexandra</li>
                  <li>Gertsch Kevin</li>
                  <li>Gutknecht Stephan</li>
                  <li>Jovic Biljana</li>
                  <li>Keller Simon</li>
                  <li>Meyer Esther</li>
                  <li>Pasquier Anne-Florence</li>
                  <li>Schläfli Laila</li>
                  <li>Schweizer Ramona</li>
                  <li>Topiwala Gita</li>
                  <li>Vonaesch Livia</li>
                  <li>Vuille Christof</li>
                  <li>Zimmerli Jill</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2013-2015'>
                <ul>
                  <li>Bischoff Viviane</li>
                  <li>Bolzli Michael</li>
                  <li>Brunner Céline</li>
                  <li>Debieux Thierry</li>
                  <li>Enggist Manuela</li>
                  <li>Fischli Charlotte</li>
                  <li>Graf-Kaiser Michèle</li>
                  <li>Greiner Vinzenz</li>
                  <li>Grolimund Joel</li>
                  <li>Kadic Vanja</li>
                  <li>Krapf Céline</li>
                  <li>Leitner Linda</li>
                  <li>Marti Simon</li>
                  <li>Meier Sarah</li>
                  <li>Schärer Kim</li>
                  <li>Spinnler Claudia</li>
                  <li>Studer Leonie</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2016-2018'>
                <ul>
                  <li>Bernet Remo</li>
                  <li>Boscardin Simona</li>
                  <li>Broccard Pauline</li>
                  <li>Brunner Marco</li>
                  <li>Decasper Anaïs</li>
                  <li>Degonda Silvana</li>
                  <li>Forster Sven</li>
                  <li>Frischknecht Anina</li>
                  <li>Germann Andrea</li>
                  <li>Hobi Andreas</li>
                  <li>Lardi Carlo</li>
                  <li>Schraner Remo</li>
                  <li>Wicki Florian</li>
                  <li>Zingg Rahel</li>
                  <li>Zobrist Anja</li>
                  <li>Zygmont Noah</li>
                </ul>
              </CollapseText>

              <CollapseText text='Jahrgang 2019-2021'>
                <ul>
                  <li>Euchner Celina</li>
                  <li>Frautschi Karin</li>
                  <li>Giger Fabio</li>
                  <li>Hämmerli Rachel</li>
                  <li>Lippert Lukas </li>
                  <li>Mathys Elena </li>
                  <li>Müller Fynn </li>
                  <li>Rajic Toni </li>
                  <li>Reinhardt Jan-Niklas </li>
                  <li>Schmid Helena </li>
                  <li>Triaca Ladina </li>
                  <li>Vodermayer Vanessa </li>
                  <li>Zehnder Lara </li>
                  <li>Ziegler Sven </li>
                </ul>
              </CollapseText>

            </Content>
          </SubPage>


      </StdPage>
    );
  }
}

export default Alumni;